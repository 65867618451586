
export const environment = {
   production: false,
   api: 'https://sa-dashboard-api.dchmsc.com/api/',
   SSOLogin: {
      clientId: 'c95e4ded-41a4-4cf3-b443-74d18ddc7ee6',
      authority: 'https://login.microsoftonline.com/0c354a30-f421-4d42-bd98-0d86e396d207',
      graph_endpoint: 'https://graph.microsoft.com/v1.0/me',
    }
  };
